export default defineNuxtRouteMiddleware(async (to) => {
  const { user, isOwner } = useUser()
  if (!isOwner.value) return navigateTo({ name: 'index' })

  try {
    const { $api } = useNuxtApp()
    const { data } = await $api.v3.userHouses.read(to.params.name)
    if (!user.value.housesIds.includes(data.id))
      return navigateTo({ name: 'index' })
  } catch (err) {
    console.error(err)
  }
})
